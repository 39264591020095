// This page is for showing a single article to a user who isn't signed in

// It charges $2 via paypal, and acts as a sign-up for the email used with checkout, calling signupWithPg


import {
  Container, Avatar, Typography, Card, CardHeader,
  CardContent, CardMedia, IconButton, Box, Button, useMediaQuery, Divider,
  stepConnectorClasses
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'; // todo: change thumbup icon state when article liked
import { Header } from 'semantic-ui-react'; // this is used in paypalbuttonwrapper
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import { useNavigation, Outlet, useOutletContext } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getPgData, postPgData, patchPgData, signupWithPg } from '../data/rdsClient';
import { useParams } from 'react-router-dom';
import { useId, useRef } from 'react';

// Import a markdown parser (Markdown-it is a popular choice)
import MarkdownIt from "markdown-it";
import markdownItMark from 'markdown-it-mark'; // underlining isn't a standard markdown feature
import { set } from 'lodash';

// import stuff we need for paypal
import {
  PayPalScriptProvider,
  usePayPalScriptReducer,
  PayPalButtons,
} from "@paypal/react-paypal-js";
const SCRIPT_PROVIDER_OPTIONS = {
  clientId: "AXAfPQX0u6EE4C_GNEmBQfC-djR03rWq2Yw6Mq_Op_dkgnjYikJ0AtrIEAhIbj3Kfj7onD407v1GkWKI"
  , components: "buttons"
  , currency: "USD"
  , 'disable-funding': 'paylater'
};
const CUSTOM_FIELD_STYLE = { "border": "1px solid #606060", "boxShadow": "2px 2px 10px 2px rgba(0,0,0,0.1)" };
const INVALID_COLOR = {
  color: "#dc3545",
};
// This value is from the props in the UI
const style = { "layout": "vertical" };
// end of stuff we need for paypal

// Instantiate mdParser
const mdParser = new MarkdownIt({
  html: true, // Enable HTML tags in source
  linkify: true, // Autoconvert URL-like text to links
  typographer: true, // Enable some nice transformations (like (c) -> ©)
}).use(markdownItMark);

// add a function for parsing the article timestamps
const { DateTime } = require('luxon');


const generate_code = () => {
  const length = 6;
  let code = "";
  for(let i=0;i<length;i++){
      code += Math.floor(Math.random() * 10);
  }
  return code;
}

const generate_OTP = () => {
  const length = 15;
  let OTP = "";
  for(let i=0;i<length;i++){
      OTP += Math.floor(Math.random() * 10);
  }
  return OTP;
}

export const ArticleView = () => {
  //Introduce State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => { setErrorMessage(message); };
  const resetError = () => { setErrorMessage(null); };
  // Step 1: Introduce State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => { setSuccessMessage(message); };
  const resetSuccess = () => { setSuccessMessage(null); };

  const observerTarget = useRef(null); // hook for infinite scroll with Intersection Observer API

  const commentTextAreaId = useId(); // hook for CommentForm
  const { articleIdUrlParam } = useParams();
  const navigation = useNavigation();
  const navigate = useNavigate();
  const isLoading = // set isLoading for comment form
    navigation.state === 'loading' || navigation.state === 'submitting';
  //Workflow for all subabase quieres
  const session = useOutletContext(null); //Session store user token
  const [ArticleContentFromRds, setArticleContentFromRds] = useState([]); //Gets article for user from RDS
  // displaying comments
  const [comments, setComments] = useState([]); // need to fetch comments when displaying
  const [commentsForArticle, setCommentsForArticle] = useState([]); // which article in the DOM?
  // displaying article content
  const [DisplayContentId, setDisplayContentId] = useState(null); // which article in the DOM?
  const [web_anon_article_purchase, setweb_anon_article_purchase] = useState(false); // Did a web_anon try to buy an article?
  // buying articles and dealing with balance/artices_bought/etc.
  console.log('ArticleView, session=', session)
  console.log('ArticleContentFromRds= ', ArticleContentFromRds)
  console.log('articleIdUrlParam= ', articleIdUrlParam)

  // rerendering thumbs on new likes
  const [articles_liked, setArticles_liked] = useState()
  // add a function for parsing the article timestamps
  function parseTimestamp(timestamp) {
    // Create a Luxon DateTime object from the timestamp
    const dt = DateTime.fromISO(timestamp, { zone: 'utc' });

    // Get the current UTC time
    const now = DateTime.utc();

    // Calculate the difference in days between now and the timestamp
    const diffInDays = now.diff(dt, 'days').toObject().days;

    // If the difference is greater than or equal to 1 day, display m/d/y format
    if (diffInDays >= 1) {
      return dt.toFormat('LL/dd/yyyy');
    } else {
      // Get the day of the week and format it in English
      const dayOfWeek = dt.toFormat('EEEE');

      // Get the time of day in 12-hour HH:mm a format (with AM/PM)
      const timeOfDay = dt.toFormat('hh:mm a');

      // Get the English time zone name
      const timeZoneName = dt.toFormat('ZZZZ');

      return `${dayOfWeek}, ${timeOfDay} ${timeZoneName}`;
    }
  }
  // const timestamp = '2023-09-13T23:03:53.011426+00:00';
  // const result = parseTimestamp(timestamp);
  // console.log(result); // Example output: "Wednesday, 11:03 PM UTC"


  // todo: display hashtags as links in blurbs
  function parseBlurb(blurb) {
    // Define a regular expression pattern to match hashtags
    // var regex = /#(\w+)\s/g;
    // match hashtags that end with a ' ' or with the end of the string:
    var regex = /#\w+(?=\s|$)/g;
    // Use the regular expression to find hashtags
    var hashtagsWithHash = blurb.match(regex) || [];
    // Trim the leading '#' from each hashtag
    var hashtags = hashtagsWithHash.map(hashtagWithHash => hashtagWithHash.substring(1));

    // Replace occurrences of the first 5 hashtags with links
    var parsedBlurb = blurb;
    hashtags.slice(0, 5).forEach(hashtag => {
      const hashtagLink = `<a href="/hashtag/${hashtag}">#${hashtag}</a>`;
      parsedBlurb = parsedBlurb.replace(new RegExp(`#${hashtag}\\b`, 'g'), hashtagLink);
    });

    // Return the parsed blurb
    return parsedBlurb;
  }

  // todo: take an articleId as URL Param
  async function fetchArticles(article_id) {
    try {
      const topArticle = await getPgData(`/articlefresh?id=eq.${article_id}`); // todo: examine session param for default value case
      console.log('topArticle: ', topArticle)
      setArticleContentFromRds(topArticle.data[0])
      console.log('ArticleView topArticle query: ', topArticle.data[0]);
      return
    } catch (error) {
      console.log(error);
      setErrorMessage('Something went wrong.  Please email us at singlepaynews@gmail.com')
      throw error;
    }
  }

  // Asynchronous function to fetch notifications
  async function fetchNotifications() {
    console.log('fetchNotifications');
    let token = '';
    if (session.data) { // will fail when not logged in
      console.log('fetchNotifications session=', session) // may not be signed in
      token = session ? session.data.session.access_token : null // todo: examine session param for default value case
    } else {
      token = ""
      console.log('no session.data, not fetchNotifications')
    }
    try {
      // getPgData notifications where is = session...id 
      const { data: notificationsData, error: notificationsError } = await getPgData(`/notifications?owner_uid=eq.${session.data.session.userPublic.id}`, {}, session);// todo: examine session param for default value case

      if (notificationsError) {
        console.error('Error:', notificationsError);
        console.log(notificationsError.message);
        return { error: notificationsError.message };
      }

      console.log('getPgData - fetchNotifications:', notificationsData);
      // setNotifs(notificationsData); // setNotifs to the full array

      // Filter unchecked notifications and update count
      const uncheckedNotifications = notificationsData.filter(notification => !notification.is_checked);
      console.log('uncheckedNotifications: ', uncheckedNotifications)
      console.log('uncheckedNotifications.length: ', uncheckedNotifications.length)
      // update state var in session context
      session.update_uncheckedNotifs(uncheckedNotifications.length); // Update context
    } catch (error) {
      console.error('fetchNotifications error:', error);
      return { error: error.message };
    }
  }


  async function fetchComments(article_id) {
    setCommentsForArticle(article_id) // which article in the DOM?
    try {
      const response = await getPgData(`/comment?parent_article=eq.${article_id}&order=num_likes.desc,parent_comment.nullsfirst`); // todo: examine session param for default value case
      if (Array.isArray(response.data)) {
        setComments(response.data); // Set the response data if it's an array
        console.log('ArticleView comments query: ', response.data);
      } else {
        console.error('Response data is not an array:', response.data);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }


  async function readArticle(post, session) {
    console.log('readArticle post: ', post);
    console.log('readArticle session: ', session);
    if (!session.data) { // If the user is not signed in 
      if (post.is_paid) { //and the article is paid
        console.log('User not signed in, show PayPal button');
        setweb_anon_article_purchase(true); // show paypal buttons to buy just one article
        // setDisplayContentId to show paypal buttons at the correct article
        setDisplayContentId(post.id);
        return;
      } else {
        // Free article for non-signed-in user
        setweb_anon_article_purchase(false);
        setDisplayContentId(post.id);
        return;
      }
    }

    if (post.is_paid) { // if the user is signed in and the article is paid
      const sessionData = await validateSession(session, post);
      if (!sessionData) return;

      const { userPrivate, userPublic, token } = sessionData;
      if (await hasBoughtArticle(userPrivate, post)) {
        setweb_anon_article_purchase(false);
        setDisplayContentId(post.id); // expose article content in DOM
        return;
      }

      if (userPrivate.id !== post.user_author) {
        const canBuy = await validateUserBalance(userPrivate);
        if (canBuy) {
          await purchaseArticle(post, sessionData);
        } else {
          handleError('Out of tokens, buy more to read');
        }
      } else {
        console.log('User is the author of the article');
        setweb_anon_article_purchase(false);
        setDisplayContentId(post.id); // expose article content in DOM
      }
    } else { // if user is signed in and the article is free
      handleFreeArticle(post, session);
    }
  }

  // Validate session and return session data if valid
  async function validateSession(session, post) {
    //TODO: this is gonna throw cannot access prop of undefined I just know it.
    if (session && session.data && session.data.session.access_token && session.data.session.userPublic) {
      const userPrivate = session.data.session.userPrivate;
      if (userPrivate && userPrivate.id) {
        return { userPrivate, userPublic: session.data.session.userPublic, token: session.data.session.access_token };
      }
    }
    // jeez i dunno something terrible has happened you should probably burn this page to the ground and start over
    console.log('jeez i dunno something terrible has happened you should probably burn this page to the ground and start over');
    return null;
  }
  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------

  // Check if the user has already bought the article
  async function hasBoughtArticle(userPrivate, post) {
    if (userPrivate.articles_bought_articleid == null) {
      userPrivate.articles_bought_articleid = [];
    }
    return userPrivate.articles_bought_articleid.includes(post.id);
  }

  // Check if user has enough tokens to buy the article
  async function validateUserBalance(userPrivate) {
    if (userPrivate.balance >= 25) {
      return true;
    } else {
      console.log('User does not have enough tokens:', userPrivate.balance);
      return false;
    }
  }
  // Handle article purchase logic
  async function purchaseArticle(post, sessionData) {
    console.log('purchaseArticle sessionData: ', sessionData)
    const { userPrivate, userPublic } = sessionData;

    const articleboughtby_Data = {
      purchasing_user_id: userPublic.id,
      article_id: post.id,
      when: 'NOW()',
      uid_of_article_author: post.user_author,
      purchasing_user_name: userPublic.name,
      author_name: post.author_name,
      article_title: post.title
    };

    try {
      const response = await postPgData(`/articleboughtby`, articleboughtby_Data, sessionData.token);
      if (response.data) {
        setweb_anon_article_purchase(false);
        setDisplayContentId(post.id); // expose article content in DOM
        await handleAutoFollow(post, sessionData);
        await notifyAuthor(post, sessionData, 'bought');
        await updateSession(sessionData);
      } else {
        handleError('Something went wrong; try signing in again?');
      }
    } catch (error) {
      handleError(error);
    }
  }

  // Handle free articles
  async function handleFreeArticle(post, session) {
    setweb_anon_article_purchase(false);
    setDisplayContentId(post.id);
    await notifyAuthor(post, session, 'read');
  }

  // Send notification to the article's author
  async function notifyAuthor(post, sessionData, action) {
    console.log('notifyAuthor sessionData: ', sessionData)
    const { userPrivate, userPublic, token } = sessionData;

    // Declare the notification object outside of the if-else block
    let notification;
    // bugfix: notifyAuthor needs to be able to handle web_anon, in which case userPublic is undefined
    if (userPublic) { // if signed in
      notification = {
        trigger_uid: userPublic.id,
        trigger_uname: userPublic.name,
        action,
        subject_aid_uid: post.id,
        subject_title_name: post.title,
        owner_uid: post.user_author
      };
    } else { // if web_anon
      notification = {
        trigger_uid: null,
        trigger_uname: 'Someone',
        action,
        subject_aid_uid: post.id,
        subject_title_name: post.title,
        owner_uid: post.user_author
      };
    }

    const { error } = await postPgData(`/notifications`, notification, sessionData.token);
    if (error) console.error('Error:', error);
  }

  // Handle auto-follow for authors and hashtags
  async function handleAutoFollow(post, sessionData) {
    console.log('handleAutoFollow sessionData: ', sessionData)
    const { userPrivate, userPublic, token } = sessionData;

    if (!userPublic.following_ids.includes(post.user_author)) {
      await postPgData('/rpc/follow_user', { p_follower_uid: userPublic.id, p_uid_followed: post.user_author }, sessionData.token);
    }

    for (const tag of post.tag_names) {
      if (!userPublic.hashtags_following_text.includes(tag)) {
        await postPgData('/rpc/follow_hashtag', { p_user_id: userPublic.id, p_hashtag_text: tag }, sessionData.token);
      }
    }
  }

  // Update session after purchase
  async function updateSession(sessionData) { // TODO: I AM HIGHLY SUSPICIOUS OF THIS
    console.log('updateSession sessionData: ', sessionData)
    const { data: userPrivateData } = await getPgData(`/userprivate?id=eq.${sessionData.userPublic.id}`, {}, sessionData.token);
    const { data: userPublicData } = await getPgData(`/userpublic?id=eq.${sessionData.userPublic.id}`, {}, sessionData.token);

    let newSession = { ...sessionData, userPrivate: userPrivateData[0], userPublic: userPublicData[0] };
    sessionData.login(newSession); // update session provider
  }

  // todo: lol add unlike article
  async function handleArticleLike(articleId, articleTitle, article_authorId) {
    // num_likes on article is incremented by on insert trigger against spn.likes table
    console.log('handleArticleLike');
    let token = '';
    let userId = '';
    if (session.data) {
      console.log('handleArticleLike session=', session)
      token = session.data.session.access_token
      userId = session.data.user.id
    } else {
      handleError('Are you logged in?')
      return 'not logged in'
    }

    try {
      // num_likes on article is incremented by on insert trigger against spn.likes table
      const { data: likesData, error: likesError } = await getPgData(`/likes?thing_liked=eq.${articleId}&user_liking=eq.${userId}`, {}, session);

      if (likesError) {
        console.error('Error:', likesError);
        console.log(likesError.message);
        return { error: likesError.message };
      }

      console.log('getPgData - Likes:', likesData);

      //  Now use the data from the first call in the second call
      // if not in likes
      // postPgData likes
      if (likesData.length === 0) {
        const { data: postLikeData, error: postLikeError } = await postPgData(
          `/likes`,
          {
            thing_liked: articleId,
            user_liking: userId,
            type_of_thing_liked: 'article'
          },
          session
        );

        if (postLikeError) {
          console.error('Error:', postLikeError);
          console.log(postLikeError.message);
          return { error: postLikeError.message };
        }

        console.log('postPgData - Likes:', postLikeData);
        // getPgData `/userprivate?id=eq.${session.data.user.id}`
        // todo: this should maybe be saved in session?
        // todo: if we put articles_liked in userPublic instead of userPrivate it'll be in session.
        const { data: userprivateLikes, error: userprivateLikesError } = await getPgData(
          `/userprivate?id=eq.${userId}&select=articles_liked`,
          {},
          session
        );

        if (userprivateLikesError) {
          console.error('Error:', userprivateLikesError);
          console.log(userprivateLikesError.message);
          return { error: userprivateLikesError.message };
        }

        console.log('getPgData - userprivate Likes:', userprivateLikes);

        let updated_userprivate_articles_liked = userprivateLikes[0].articles_liked;
        if (updated_userprivate_articles_liked === null) { // what if this is their first like?
          updated_userprivate_articles_liked = [];
        }
        updated_userprivate_articles_liked.push(articleId);
        // patchPgData `/userprivate?id=eq.${session.data.user.id}`
        const { data: userLikesPatch, error: userLikesPatchError } = await patchPgData(
          `/userprivate?id=eq.${userId}`,
          { articles_liked: updated_userprivate_articles_liked },
          session
        );

        if (userLikesPatchError) {
          console.error('Error:', userLikesPatchError);
          console.log(userLikesPatchError.message);
          return { error: userLikesPatchError.message };
        }

        console.log('patchPgData - userprivate Likes:', userLikesPatch);

        // update setState
        setArticles_liked(updated_userprivate_articles_liked);

        // add to notifications table
        // notifications are default false
        console.log('postPgData - notifications:');
        // getPgData `/userprivate?id=eq.${session.data.user.id}`
        // todo: this should maybe be saved in session?
        // todo: if we put articles_liked in userPublic instead of userPrivate it'll be in session.
        const { data: postPgNotification, error: postPgNotificationError } = await postPgData(
          `/notifications`,
          {
            // Default: eid bigserial PRIMARY KEY,
            // Default: created timestamp with time zone DEFAULT NOW(),
            // Default: updated timestamp with time zone DEFAULT NOW(),
            // Default: type text,
            trigger_uid: session.data.user.id,
            trigger_uname: session.data.user.username,
            action: 'liked', // this gets used in the string in NotificationsView; see notifications.txt lines 269-274.
            subject_aid_uid: articleId,
            subject_title_name: articleTitle, // had to add this as a input to handleArticleLike() function
            owner_uid: article_authorId // had to add this as a input to handleArticleLike() function
          },
          session
        );

        if (postPgNotificationError) {
          console.error('Error:', postPgNotificationError);
          console.log(postPgNotificationError.message);
          return { error: postPgNotificationError.message };
        }

        console.log('postPgNotification: ', postPgNotification)

        // update stored session
        let newSessionData = session;
        newSessionData.data.session.userPrivate.articles_liked = updated_userprivate_articles_liked;
        session.login(newSessionData);

        return likesData;

      } else {
        console.log('already liked')
        return 'already liked'
      }
    } catch (error) {
      console.error('handleArticleLike error:', error);
      return { error: error.message };
    }
  }

  // First useEffect(): This handles fetching the article data only when the user is not signed in. 
  // It fetches the article once and stores it in ArticleContentFromRds.
  useEffect(() => {
    console.log('Fetching article for anonymous user.');
    const loadData = async () => {
      await fetchArticles(articleIdUrlParam); // Fetch article data
    };

    if (!session?.data?.session?.userPrivate) {
      loadData(); // Fetch data only for non-logged-in users
    } else {
      console.log('Signed in, navigating to regular feedview version');
      navigate(`/${articleIdUrlParam}`);
    }
  }, [articleIdUrlParam, session]);

  // Second useEffect(): This watches for changes in ArticleContentFromRds 
  // and only triggers the readArticle() function when the article content
  // is available (i.e., when 
  // ArticleContentFromRds.content and ArticleContentFromRds.id are defined).
  useEffect(() => {
    if (ArticleContentFromRds.content && ArticleContentFromRds.id) {
      readArticle(ArticleContentFromRds, session); // Now the data is loaded, fire the function
    }
  }, [ArticleContentFromRds, session]);


  //Returns 'More - Paid' if value == true or 'More - False' if value == false or null
  const isMoreFree = (value) => value ? <Button size='large'>More - $0.25</Button> : <Button size='large'>More - Free!</Button>

  //Returns true if post.id is in useState articles_liked, false otherwise
  const isLiked = (value, articles_liked) => articles_liked ? articles_liked.includes(value) : false

  // For media queries under 900px viewport
  const viewport = useMediaQuery('(min-width: 680px)');

  function CommentCard(comment) {
    console.log('CommentCard: ', comments)
    console.log('CommentCard: ', comment) // undefined
    return (
      <>
        <Card sx={{ height: '100%', marginBottom: '1rem', marginTop: '1rem', borderBottom: '1px solid white' }}>
          <CardHeader
            avatar={
              <Avatar src={comment.author_profile_pic_url} alt={comment.author_name} sx={{ width: '40px', height: '40px' }}></Avatar>
            }
            title={
              <Typography varient='body1'><a href={`/user/${comment.author_id}`}> By {comment.author_name}</a></Typography>
            }
            subheader={parseTimestamp(comment.created)}
          />
          <CardContent>
            <Typography variant={viewport ? 'h6' : 'subtitle1'} gutterBottom paragraph>
              {comment.text}
            </Typography>
            {/* ToDo: icons, liking comments */}
          </CardContent>
        </Card>
        {comment.children.length > 0 && (
          <ul>
            {comment.children.map(c => (
              <CommentCard comment={c} key={c.comment_id} />
            ))}
          </ul>
        )}
      </>
    )
  }

  // TODO: can this be export default in its own components/CommentForm.jsx file?
  function CommentForm(post) { // https://react.dev/reference/react-dom/components/textarea#reading-the-text-area-value-when-submitting-a-form
    async function handleSubmit(e) {
      // Prevent the browser from reloading the page
      e.preventDefault();
      // Read the form data
      const form = e.target;
      const formData = new FormData(form);
      // You can pass formData as a fetch body directly:
      // fetch('/some-api', { method: form.method, body: formData });
      // Or you can work with it as a plain object:
      const formJson = Object.fromEntries(formData.entries());
      console.log(formJson);
      //{commentContent: 'comment'}

      // post comment to table comments
      const commentRequestBody = { // TODO: comment ID was 348 for article 347, it should have been 1
        // id : default, don't input
        // type : default, don't input
        // created : default, don't input
        // updated : default, don't input
        text: formJson.commentContent,
        author_uid: session.data.session.userPublic.id,
        parent_article: commentsForArticle, // stateVar of article showing comments
        parent_comment: null, // TODO
        num_likes: 0, // start with 0; TODO: make this a default in backend
        users_liking: [], // start with []; TODO: make this a default in backend
        author_profile_pic_url: session.data.session.userPublic.profile_pic_url,
        author_name: session.data.session.userPublic.name,
        num_reports: 0, // start with 0; TODO: make this a default in backend
        users_reporting: [], // start with []; TODO: make this a default in backend
        article_author_reported: false, // start with false; TODO: make this a default in backend
        author_id: session.data.session.userPublic.id, // TODO: duplicate, remove
        children: [] // start with []; TODO: make this a default in backend
      }
      // add the comment to the comment table
      const { data: commentData, error: commentError } = await postPgData('/comment', commentRequestBody, session);

      if (commentError) {
        console.error('Error:', commentError);
        console.log(commentError.message);
        return { error: commentError.message };
      }

      console.log('postPgData - comment:', commentData);
      // notify author that someone commented on their article
      // add to notifications table
      // notifications are default false
      console.log('postPgData - notifications:');
      // getPgData `/userprivate?id=eq.${session.data.user.id}`
      // todo: this should maybe be saved in session?
      // todo: if we put articles_liked in userPublic instead of userPrivate it'll be in session.
      const { data: postPgNotification, error: postPgNotificationError } = await postPgData(
        `/notifications`,
        {
          // Default: eid bigserial PRIMARY KEY,
          // Default: created timestamp with time zone DEFAULT NOW(),
          // Default: updated timestamp with time zone DEFAULT NOW(),
          // Default: type text,
          trigger_uid: session.data.session.userPublic.id,
          trigger_uname: session.data.session.userPublic.name,
          action: 'commented on', // this gets used in the string in NotificationsView; see notifications.txt lines 269-274.
          subject_aid_uid: post.id,
          subject_title_name: post.title, // had to add this as a input to handleArticleLike() function
          owner_uid: post.user_author // had to add this as a input to handleArticleLike() function
        },
        session
      );

      if (postPgNotificationError) {
        console.error('Error:', postPgNotificationError);
        console.log(postPgNotificationError.message);
        return { error: postPgNotificationError.message };
      }

      console.log('postPgNotification: ', postPgNotification)

      // ToDo: use params to reload page with comment posted & article at the top?
      // get the new list of comments
      fetchComments(commentsForArticle)
      // todo: close the text entry box, or at least blank it out
    }
    const opened_article = (post.id == DisplayContentId) // opened_article true when 'more' clicked, else false
    const isEmpty = (post.content == "") // isEmpty true when content empty, else false
    return (
      (opened_article || isEmpty) ? ( // If you're bought the article, or if its content is empty, because we don't display the 'see more' button for empty content articles
        <>
          {
            comments ? comments.map(comment =>
            (
              CommentCard(comment)
            )
            ) : <h2>no comments yet</h2>
          }

          <form method="post" onSubmit={handleSubmit}>
            <label htmlFor={commentTextAreaId}></label>
            {/* 2000 character limit for comments, 1800 chars is a normal double space page and I'm concerned about comments much longer than that */}
            <textarea
              id={commentTextAreaId}
              name="commentContent"
              rows={2}
              //cols={120}
              maxlength="2000"
              style={{ width: '70%', paddingBottom: '2rem', paddingTop: '.5rem', paddingLeft: '.5rem', borderRadius: '3px', borderColor: '0f0f0f' }}
            />
            <Button primary size='large' disabled={isLoading} type='submit'>{isLoading ? 'loading...' : 'Post Comment'}</Button>
          </form>
        </>
      ) : ( // article unopened
        <>
          {
            comments ? comments.map(comment =>
            (
              CommentCard(comment)
            )
            ) : <h2>no comments yet</h2>
          }
          <form method="post" onSubmit={handleSubmit}>
            <label htmlFor={commentTextAreaId}></label>
            {/* 2000 character limit for comments, 1800 chars is a normal double space page and I'm concerned about comments much longer than that */}
            <textarea
              id={commentTextAreaId}
              name="commentContent"
              rows={2}
              //cols={120}
              maxlength="2000"
              style={{ width: '70%', paddingBottom: '2rem', paddingTop: '.5rem', paddingLeft: '.5rem', borderRadius: '3px', borderColor: '0f0f0f' }}
            />
            <Button primary size='large' disabled={!opened_article} type='submit'>{isLoading ? 'loading...' : 'Must Open Article to Comment'}</Button>
          </form>
        </>
      )
    ); // end of return
  } // end of CommentForm(post);

  function articleContentComponent(post) {
    console.log('articleContentComponent: ', post);

    if (web_anon_article_purchase) {
      return (
        <>
          <div style={{ maxWidth: "750px", minHeight: "200px" }}>
            <PayPalScriptProvider options={SCRIPT_PROVIDER_OPTIONS}>
              <ButtonWrapper showSpinner={false} quantity={2} handleError={handleError} handleSuccess={handleSuccess} />
            </PayPalScriptProvider>
          </div>
        </>
      );
    } else {
      console.log('Rendering content...');
      const content = typeof post.content === 'string' ? post.content : '';
      const htmlContent = mdParser.render(content);

      // Log the rendered HTML to ensure it’s valid
      console.log('Rendered HTML:', htmlContent);

      return (
        <>
          <Card sx={{ height: '100%', marginBottom: '1rem', borderBottom: '1px solid white' }}>
            <CardContent>
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </CardContent>
          </Card>
        </>
      );
    }
  }

  const renderMediaContent = (post) => {
    if (post.image_url == "") {
      return (<></>)
    } else if (post.image_url == null) {
      return (<></>)
    }
    if (post.image_url !== "") {
      if (post.image_url.match(/\.(jpeg|jpg|gif|png|bmp|svg|JPEG|JPG|GIF|PNG|BMP|SVG)$/i)) {
        return (
          <CardMedia
            component="img"
            sx={{
              width: '95%',
              margin: '0 auto',
              maxWidth: '750px',
              maxHeight: '950px',
            }}
            image={post.image_url}
            title={post.author_name}
          />
        );
      } else if (post.image_url.match(/\.(webm|mp4|avi|quicktime|x-matroska|x-ms-wmv)$/i)) {
        let type = '';
        if (post.image_url.match(/\.(webm)$/i)) {
          type = 'video/webm';
        } else if (post.image_url.match(/\.(mp4)$/i)) {
          type = 'video/mp4';
        } else if (post.image_url.match(/\.(avi)$/i)) {
          type = 'video/avi';
        } else if (post.image_url.match(/\.(quicktime)$/i)) {
          type = 'video/quicktime';
        } else if (post.image_url.match(/\.(x-matroska)$/i)) {
          type = 'video/x-matroska';
        } else if (post.image_url.match(/\.(x-ms-wmv)$/i)) {
          type = 'video/x-ms-wmv';
        }

        return (
          <CardMedia
            component="video"
            controls
            sx={{
              width: '95%',
              margin: '0 auto',
              maxWidth: '750px',
              maxHeight: '950px',
            }}
            src={post.image_url}
            title={post.author_name}
            type={type}
          />
        );
      }
    }
    return null;
  };


  // ----------------------------------------------------------------------------
  //  PayPal Functions
  // ----------------------------------------------------------------------------
  function createOrder(handleError) {
    // replace this url with your server
    return fetch("/api/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // use the "body" param to optionally pass additional order information
      // like product ids and quantities
      body: JSON.stringify({
        cart: [
          {
            sku: "1blwyeo8",
            quantity: 2,
          },
        ],
      }),
    })
      //
      .then(response => response.ok ? response.json() : response.json().then(errorData => {
        handleError(`Error: ${errorData.error}`);
        return Promise.reject(new Error('HTTP error'));
      }))
      .then(order => {
        // Your code here after creating the order
        console.log('.then order', order)
        console.log('.then order.id', order.id)
        return order.id;
      })
      .catch(error => {
        console.error("An error occurred while creating the order:", error);
        handleError("An error occurred while creating the order. Please refresh and try again.");
        return null;
      });
  }

  function onApprove(data, session, navigate, handleError, handleSuccess) {
    // replace this url with your server
    return fetch("/api/capture", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderID: data.orderID,
      }),
    })
      .then((response) => response.json())
      .then(async (orderData) => {
        // Your code here after capturing the order
        console.log('onApprove session:', session);
        // You can use the 'session' variable here as needed

        // add transaction to token_purchases for internal accounting
        // identifying information:
        const payer_email = orderData.payer.email_address
        const payer_firstname = orderData.payer.name.given_name
        const payer_lastname = orderData.payer.name.surname
        const payer_paypal_id = orderData.payer.payer_id
        const payment_source_paypal_account_id = orderData.payment_source.paypal.account_id
        const payment_source_paypal_account_status = orderData.payment_source.paypal.account_status
        const payment_source_email_address = orderData.payment_source.paypal.email_address
        const payment_source_firstname = orderData.payment_source.paypal.name.given_name
        const payment_source_lastname = orderData.payment_source.paypal.name.surname
        // capture information
        const payment_capture_id = orderData.purchase_units[0].payments.captures[0].id
        const payment_capture_amount = orderData.purchase_units[0].payments.captures[0].amount
        const payment_capture_create_time = orderData.purchase_units[0].payments.captures[0].create_time
        const payment_capture_dispute_categories = orderData.purchase_units[0].payments.captures[0].seller_protection.dispute_categories
        const payment_capture_dispute_status = orderData.purchase_units[0].payments.captures[0].seller_protection.status
        // seller_receivable information
        const payment_capture_seller_receivable_breakdown_gross_amount = orderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.gross_amount
        const payment_capture_seller_receivable_breakdown_net_amount = orderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.net_amount
        const payment_capture_seller_receivable_breakdown_paypal_fee = orderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.paypal_fee
        const payment_capture_seller_receivable_breakdown_status = orderData.purchase_units[0].payments.captures[0].status
        const payment_capture_seller_receivable_breakdown_update_time = orderData.purchase_units[0].payments.captures[0].update_time
        // 1) record transaction to internal accounts
        const payment_capture_seller_receivable_breakdown_gross_amount_value_as_float = parseFloat(payment_capture_seller_receivable_breakdown_gross_amount.value);
        const payment_capture_seller_receivable_breakdown_net_amount_value_as_float = parseFloat(payment_capture_seller_receivable_breakdown_net_amount.value);
        const payment_capture_seller_receivable_breakdown_paypal_fee_value_as_float = parseFloat(payment_capture_seller_receivable_breakdown_paypal_fee.value);
        console.log('floor(net*100): ', Math.floor(payment_capture_seller_receivable_breakdown_net_amount_value_as_float * 100))
        const tokens_added = 25// just make this 25 so new users can buy another articleMath.floor(20);
        // Removed math.round().  Said 8 for purchase of 816 tokens.
        const calculated_net_amount_to_tokens = tokens_added / 100; // tokens as pennies
        const calculated_SPN_fee_for_webanon_purchase = payment_capture_seller_receivable_breakdown_net_amount_value_as_float - calculated_net_amount_to_tokens;
        const tokenPurchase_formDataEscape = {
          //internal_transaction_id: default,
          external_transaction_id: payment_capture_id,
          total_amount: payment_capture_seller_receivable_breakdown_gross_amount.value, //Why did it charge $10 and not $20?
          net_amount_to_tokens: calculated_net_amount_to_tokens, // paypal net minus $1.00 spn fee
          external_fee: payment_capture_seller_receivable_breakdown_paypal_fee.value,
          spn_fee: calculated_SPN_fee_for_webanon_purchase.value,
          user_id: null, // user does exist
          time: 'NOW()',
          user_email: payer_email,
          user_firstname: payer_firstname,
          user_lastname: payer_lastname,
          user_paypal_id: payer_paypal_id,
          tokens_added: tokens_added
        };
        console.log('payment_capture_seller_receivable_breakdown_gross_amount_value_as_float: ', payment_capture_seller_receivable_breakdown_gross_amount_value_as_float);
        console.log('payment_capture_seller_receivable_breakdown_net_amount_value_as_float: ', payment_capture_seller_receivable_breakdown_net_amount_value_as_float);
        console.log('payment_capture_seller_receivable_breakdown_paypal_fee_value_as_float: ', payment_capture_seller_receivable_breakdown_paypal_fee_value_as_float);
        console.log('tokenPurchase_formDataEscape: ', tokenPurchase_formDataEscape);
        try {
          // insert purchase to token_purchases table
          const postPgDataResponse = await postPgData(
            '/token_purchases',
            tokenPurchase_formDataEscape,
            session // todo: validate that this works with no session
          )
          console.log('postPgDataResponse:', postPgDataResponse);
          // TODO: Figure out how to use handleSuccess here.  There's a data param for onApprove that's gotta be sourced.
        } catch (error) {
          console.log(error)
          throw error;
        }
        // 2) complete signup
        const formDataEscape = { // todo: security audit the random number generators (lol)
          email: payer_email.toLowerCase(),
          password: generate_OTP(), // 15 digit 0-9
          name: payer_firstname + ' ' + payer_lastname,
          code: generate_code(), // 6 digit 0-9
          tokens: tokens_added,
          articles_owned: [articleIdUrlParam] //I want articles_owned to contain the post.id
        };
        try {
          // insert to temp table basic_auth.unverified_users(email, pass, role, created, name, code)
          // TODO: add amount of tokens purchased to signUp, it's not just 821 every time anymore
          const signupResponse = await signupWithPg( // returned undefined?
            {
              input_name: formDataEscape.name,
              input_email: formDataEscape.email.toLowerCase(),
              pass: formDataEscape.password,
              input_code: formDataEscape.code,
              tokens: tokens_added
            }
          )
          // rpc/signup either returns an object.token or nothing
          console.log('signupResponse:', signupResponse);

          // TODO: check if signupwithpg worked; don't send email it it didn't.

          const signupEmailData = {
            'input_name': formDataEscape.name,
            'input_email': formDataEscape.email.toLowerCase(),
            'input_code': formDataEscape.code,
            'initial_password': formDataEscape.password
          }
          console.log('signupEmailData: ', signupEmailData)
          //sendEmail(formDataEscape.email, formDataEscape.name, formDataEscape.code);
          const options = { //Default options are marked with *
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            // not sure what credentials is for, revisit when implementing auth. //credentials: "include", // include, *same-origin, omit
            headers: {
              'Content-Type': "application/json"
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: 'strict-origin-when-cross-origin', //"no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(signupEmailData) // body data type must match "Content-Type" header
          }
          const emailerResponse = await fetch('https://singlepaynews.com/api/emailer', options)
          console.log(emailerResponse)
          // TODO: check if email worked, handleError if not
          // TODO: Figure out how to use handleSuccess here.  There's a data param for onApprove that's gotta be sourced.
          window.alert('Check your email (and spam folder) for a link to set a password and register your account!')
        } catch (error) {
          console.log(error)
          // TODO: Figure out how to use handleError here.  There's a data param for onApprove that's gotta be sourced.
          //handleError(error.message)
          throw error;
        }
        try {
          // Show article content
          setweb_anon_article_purchase(false);
        } catch (error) {
          console.log(error);
          handleError(error);
          throw error;
        }
      });
  }

  // Custom component to wrap the PayPalButtons and show loading spinner
  const ButtonWrapper = ({ showSpinner, quantity, handleError, handleSuccess }) => {
    const [{ isPending }] = usePayPalScriptReducer();
    const session = useOutletContext(); // Capture session here
    const navigate = useNavigate(); // Capture navigate here

    const onApproveWrapper = (data) => {
      onApprove(data, session, navigate, handleError, handleSuccess); // Pass handleError and handleSuccess to onApprove
    };

    return (
      <>
        {(showSpinner && isPending) && <div className="spinner" />}
        <div style={{ height: '100%', backgroundColor: '#0f0f0f', color: 'white', paddingTop: '20px' }}>
          <Container text>
            <Header as='p' inverted textAlign="center" dividing>Thank you for supporting local journalism</Header>
            <Header as='p' inverted textAlign="center" dividing>$2 to buy just this article</Header>
            <PayPalButtons
              style={style}
              disabled={false}
              forceReRender={[style, quantity]}
              fundingSource={undefined} //TODO: only allow card payments  https://stackoverflow.com/questions/74823217/how-to-only-disable-paypal-button-but-enable-paylater-button  https://developer.paypal.com/docs/checkout/standard/customize/standalone-buttons/
              createOrder={() => createOrder(handleError)}
              onApprove={onApproveWrapper} // Use the wrapper function TODO: compare/contrast this with how we pass stuff to createOrder.  Can we pass handleError and handleSuccess this way?
            />
            <Header as='p' inverted textAlign="center" dividing>If you register an account, you can buy many articles for $0.25 each</Header>
            <Header as='p' inverted textAlign="center" dividing>There are no subscriptions at SPN.</Header>
          </Container>
        </div>
      </>
    );
  }
  // ----------------------------------------------------------------------------
  //  End of PayPal Functions
  // ----------------------------------------------------------------------------
  return (
    <Container sx={{ pt: 8, color: 'white' }} maxWidth='md'>
      {ArticleContentFromRds ? ( // Check if object exists
        <Card sx={{ marginBottom: '2rem', borderBottom: '1px solid white' }}>
          <CardHeader
            avatar={
              <Avatar src={ArticleContentFromRds.author_profile_picture ? (ArticleContentFromRds.author_profile_picture) : ('')} sx={{ width: '40px', height: '40px' }} />
            }
            title={
              <Typography variant='body1'>
                <a href={`/user/${ArticleContentFromRds.user_author ? (ArticleContentFromRds.user_author) : ('')}`}> By {ArticleContentFromRds.author_name ? (ArticleContentFromRds.author_name) : ('')}</a>
              </Typography>
            }
            subheader={parseTimestamp(ArticleContentFromRds.created ? (ArticleContentFromRds.created) : (''))}
          />

          <CardContent>
            <Typography variant={viewport ? 'h4' : 'h5'} sx={{ fontWeight: 600 }} gutterBottom>
              {ArticleContentFromRds.title ? (ArticleContentFromRds.title) : ('')}
            </Typography>
          </CardContent>

          {renderMediaContent(ArticleContentFromRds)}

          <CardContent>
            <Typography variant={viewport ? 'h6' : 'subtitle1'} gutterBottom paragraph style={{ whiteSpace: 'pre-line' }}>
              <div dangerouslySetInnerHTML={{ __html: parseBlurb(ArticleContentFromRds.blurb ? (ArticleContentFromRds.blurb) : ('')) }} />
            </Typography>
            {ArticleContentFromRds.id === DisplayContentId ? (
              <Box sx={{ display: 'inline' }}>
                {(() => {
                  try {
                    return articleContentComponent(ArticleContentFromRds);
                  } catch (error) {
                    console.error('Error rendering article content:', error);
                    return <div>Error rendering content</div>;
                  }
                })()}
              </Box>
            ) : null}

            {ArticleContentFromRds.content !== "" && (
              <Box sx={{ display: 'inline' }} onClick={() => readArticle(ArticleContentFromRds, session)}>
                {isMoreFree(ArticleContentFromRds.is_paid ? (ArticleContentFromRds.is_paid) : (false))}
              </Box>
            )}

            <Box sx={{ float: 'right' }}>
              <IconButton onClick={() => handleArticleLike(ArticleContentFromRds.id, ArticleContentFromRds.title, ArticleContentFromRds.user_author)}>
                {isLiked(ArticleContentFromRds.id ? (ArticleContentFromRds.id) : (0), articles_liked) ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}
              </IconButton>

              <IconButton onClick={() => fetchComments(ArticleContentFromRds.id)}>
                <CommentIcon />{ArticleContentFromRds.num_comments ? (ArticleContentFromRds.num_comments) : (0)}
              </IconButton>

              <IconButton onClick={() => session ? navigate(`/report/article/${ArticleContentFromRds.id}`) : handleError('Are you logged in?')}>
                <ReportProblemIcon />
              </IconButton>

              <IconButton onClick={() => handleSuccess(`https://www.singlepaynews.com/${ArticleContentFromRds.id}`)}>
                <ShareIcon />
              </IconButton>
            </Box>

            {errorMessage && (
              <div className="error-message">
                <p>{errorMessage}</p>
                <button onClick={resetError}>Close</button>
              </div>
            )}

            {successMessage && (
              <div className="success-message">
                <p>{successMessage}</p>
                <button onClick={resetSuccess}>Close</button>
              </div>
            )}

            <Box sx={{ display: 'inline' }}>
              {comments && ArticleContentFromRds.id === commentsForArticle ? CommentForm(ArticleContentFromRds) : null}
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Typography variant="h6" sx={{ color: 'gray', textAlign: 'center' }}>
          Loading...
        </Typography>
      )}
      <div ref={observerTarget}></div>
    </Container>
  );

};