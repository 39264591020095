// Note the new order of parameters.  This is for ease-of-use w.r.t. default values, and has the happy side effect of forcing thoughtful updates by making old uses of these functions throw errors.

// see src/views/TestRdsClientView.js
//import { createClient } from 'rdsJsPackage'
// class rdsClient {
//     constructor(url, key){
//         this.url = url;
//         this.key = key;
//     }
// }

// todo: what about when tokens expire?  pgrst replies like this:
// [Log] getPgData:  (bundle.js, line 9522)
// Object
// code: "PGRST301"
// details: null
// hint: null
// message: "JWT expired"
// Object Prototype


// Todo: put in an .env file to hide from haxors
//const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const rdsUri = 'https://www.singlepaynews.com:3000'
const signupEndpoint = '/rpc/signup'
//const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY
//const rdsKey = ''
//export const supabase = createClient(supabaseUrl, supabaseAnonKey)
//export const rdsClient = rdsClient(rdsUri, rdsKey)

// // [Error] Response data is not an array:
// Object
// code: "PGRST301"
// details: null
// hint: null
// message: "JWT expired"
// Object Prototype
// 	(anonymous function) (bundle.js:5797)
async function graceful_token_refresh(storedSession) {
  const userId = storedSession.data.user.id;

  const options = { 
    method: "POST", 
    mode: "cors", 
    cache: "no-cache", 
    headers: {
      'Content-Type': "application/json",
      'Prefer': 'return=representation' 
    },
    credentials: 'include', 
    redirect: "follow", 
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify({ user_id: userId })
  };

  try {
    const gatrResponse = await fetch(rdsUri + '/rpc/graceful_access_token_refresh', options);
    const gatrData = await gatrResponse.json();

    if (gatrData?.refreshed_access_token) {
      return gatrData.refreshed_access_token;
    } else {
      console.error('graceful_access_token_refresh error: ', gatrData.message || 'Unknown error');
      return false;
    }
  } catch (error) {
    console.error('graceful_access_token_refresh error: ', error);
    return false; // Return false in case of an error so the calling function can handle it
  }
}


// rest get = crud read; TODO: remove window.alert and return a meaningful error
export async function getPgData(endpoint = "/error", data = {}, storedSession, url = rdsUri) {
  let Token = "";
  console.log('storedSession: ', storedSession);
  console.log('typeof storedSession: ', typeof storedSession);

  if (storedSession && typeof storedSession === "object" && storedSession?.data?.session) {
    Token = storedSession.data.session.access_token;
  } else if (typeof storedSession === "string") {
    Token = storedSession;
  } else {
    Token = "";
  }

  console.log("Token after type check: ", Token);

  // Initialize options without Authorization
  const options = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': "application/json",
      'Prefer': 'return=representation'
    },
    credentials: 'include',
    redirect: "follow",
    referrerPolicy: 'strict-origin-when-cross-origin',
  };

  // Conditionally add Authorization header if the token is not empty
  if (Token) {
    options.headers['Authorization'] = `Bearer ${Token}`;
  }

  try {
    console.log(url + endpoint, options);
    const response = await fetch(url + endpoint, options);
    const responseData = await response.json();

    if (responseData.code === "PGRST301" && responseData.message === "JWT expired") {
      const success = await graceful_token_refresh(storedSession);
      if (success) {
        let newSession = { ...storedSession };
        newSession.data.session.access_token = success;
        storedSession.login(newSession);

        // Retry request with new token
        options.headers['Authorization'] = `Bearer ${success}`;
        const responseRetry = await fetch(url + endpoint, options);
        const responseDataRetry = await responseRetry.json();
        return { data: responseDataRetry, error: null };
      } else {
        window.alert('Login expired, please sign in again.');
      }
    }

    console.log('getPgData: ', responseData);
    return { data: responseData, error: null };
  } catch (error) {
    console.error('getPgData error: ', error);
    return { data: null, error: error.message };
  }
}
/** EXAMPLE: remove window.alert and return a meaningful error
 * 
 * export async function getPgData(endpoint = "/error", data = {}, storedSession, url = rdsUri) {
  let Token = "";
  console.log('storedSession: ', storedSession);
  console.log('typeof storedSession: ', typeof storedSession);

  if (storedSession && typeof storedSession === "object" && storedSession?.data?.session) {
    Token = storedSession.data.session.access_token;
  } else if (typeof storedSession === "string") {
    Token = storedSession;
  } else {
    Token = "";
  }

  console.log("Token after type check: ", Token);

  const options = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': "application/json",
      'Prefer': 'return=representation'
    },
    credentials: 'include',
    redirect: "follow",
    referrerPolicy: 'strict-origin-when-cross-origin',
  };

  if (Token) {
    options.headers['Authorization'] = `Bearer ${Token}`;
  }

  try {
    console.log(url + endpoint, options);
    const response = await fetch(url + endpoint, options);
    const responseData = await response.json();

    if (responseData.code === "PGRST301" && responseData.message === "JWT expired") {
      const success = await graceful_token_refresh(storedSession);
      if (success) {
        let newSession = { ...storedSession };
        newSession.data.session.access_token = success;
        storedSession.login(newSession);

        options.headers['Authorization'] = `Bearer ${success}`;
        const responseRetry = await fetch(url + endpoint, options);
        const responseDataRetry = await responseRetry.json();
        return { data: responseDataRetry, error: null };
      } else {
        return { data: null, error: 'Login expired, please sign in again.' }; // Return error instead of alert
      }
    }

    console.log('getPgData: ', responseData);
    return { data: responseData, error: null };
  } catch (error) {
    console.error('getPgData error: ', error);
    return { data: null, error: error.message };
  }
}

 */

// rest Post = crud Create; TODO: remove window.alert and return a meaningful error
export async function postPgData(endpoint = "/error", data = {}, storedSession, url = rdsUri) {
  let Token = "";
  console.log('storedSession: ', storedSession);
  console.log('typeof storedSession: ', typeof storedSession);

  if (storedSession && typeof storedSession === "object" && storedSession?.data?.session) {
    Token = storedSession.data.session.access_token;
  } else if (typeof storedSession === "string") {
    Token = storedSession;
  } else {
    Token = "";
  }

  console.log("Token after type check: ", Token);

  const options = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': "application/json",
      'Prefer': 'return=representation'
    },
    credentials: 'include',
    redirect: "follow",
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify(data),
  };

  if (Token) {
    options.headers['Authorization'] = `Bearer ${Token}`;
  }

  try {
    console.log(url + endpoint, options);
    const response = await fetch(url + endpoint, options);
    const responseData = await response.json();

    if (responseData.code === "PGRST301" && responseData.message === "JWT expired") {
      const success = await graceful_token_refresh(storedSession);
      if (success) {
        let newSession = { ...storedSession };
        newSession.data.session.access_token = success;
        storedSession.login(newSession);

        options.headers['Authorization'] = `Bearer ${success}`;
        const responseRetry = await fetch(url + endpoint, options);
        const responseDataRetry = await responseRetry.json();
        return { data: responseDataRetry, error: null };
      } else {
        window.alert('Login expired, please sign in again.');
      }
    }

    console.log('postPgData: ', responseData);
    return { data: responseData, error: null };
  } catch (error) {
    console.error('postPgData error: ', error);
    return { data: null, error: error.message };
  }
}
/** EXAMPLE: remove window.alert and return a meaningful error
 * 
 * export async function postPgData(endpoint = "/error", data = {}, storedSession, url = rdsUri) {
  let Token = "";
  console.log('storedSession: ', storedSession);
  console.log('typeof storedSession: ', typeof storedSession);

  if (storedSession && typeof storedSession === "object" && storedSession?.data?.session) {
    Token = storedSession.data.session.access_token;
  } else if (typeof storedSession === "string") {
    Token = storedSession;
  } else {
    Token = "";
  }

  console.log("Token after type check: ", Token);

  const options = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': "application/json",
      'Prefer': 'return=representation'
    },
    credentials: 'include',
    redirect: "follow",
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify(data),
  };

  if (Token) {
    options.headers['Authorization'] = `Bearer ${Token}`;
  }

  try {
    console.log(url + endpoint, options);
    const response = await fetch(url + endpoint, options);
    const responseData = await response.json();

    if (responseData.code === "PGRST301" && responseData.message === "JWT expired") {
      const success = await graceful_token_refresh(storedSession);
      if (success) {
        let newSession = { ...storedSession };
        newSession.data.session.access_token = success;
        storedSession.login(newSession);

        options.headers['Authorization'] = `Bearer ${success}`;
        const responseRetry = await fetch(url + endpoint, options);
        const responseDataRetry = await responseRetry.json();
        return { data: responseDataRetry, error: null };
      } else {
        return { data: null, error: 'Login expired, please sign in again.' }; // Return error instead of alert
      }
    }

    console.log('postPgData: ', responseData);
    return { data: responseData, error: null };
  } catch (error) {
    console.error('postPgData error: ', error);
    return { data: null, error: error.message };
  }
}

 */


// rest Patch = Crud Update; TODO: remove window.alert and return a meaningful error
//"To update a row or rows in a table, use the PATCH verb.""
//https://postgrest.org/en/stable/references/api/tables_views.html#update
//TODO: add horizontal filtering to patch to prevent table-wide update
//TODO: see deletePgData for other ways to implement this safety feature
export async function patchPgData(endpoint = "/error", data = {}, storedSession, url = rdsUri) {
  let Token = "";
  console.log('storedSession: ', storedSession);
  console.log('typeof storedSession: ', (typeof storedSession));

  // Safely check for session data
  if (storedSession && typeof storedSession === "object" && storedSession?.data?.session) {
    Token = storedSession.data.session.access_token;
  } else if (typeof storedSession === "string") { // Passed as Token string
    Token = storedSession;
  } else { // No session or undefined session, use empty token
    Token = "";
  }

  console.log('inside patchPgData:', data);

  // Proceed only if endpoint contains a query string
  if (endpoint.includes('?')) {
    const options = {
      method: "PATCH",
      mode: "cors",
      cache: "no-cache",
      headers: {
        'Content-Type': "application/json",
        'Prefer': 'return=representation',
        'Authorization': `Bearer ${Token}`
      },
      credentials: 'include',
      redirect: "follow",
      referrerPolicy: 'strict-origin-when-cross-origin',
      body: JSON.stringify(data)
    };

    try {
      console.log(url + endpoint, options);
      const response = await fetch(url + endpoint, options);

      const responseData = await response.json();

      // Check for JWT expired error and handle token refresh
      if (responseData.code === "PGRST301" && responseData.message === "JWT expired") {
        const success = await graceful_token_refresh(storedSession);
        if (success) {
          let newSession = { ...storedSession };
          newSession.data.session.access_token = success;
          storedSession.login(newSession);

          const newOptions = {
            ...options,
            headers: {
              ...options.headers,
              'Authorization': `Bearer ${success}`
            }
          };
          console.log(url + endpoint, newOptions);
          const responseRetry = await fetch(url + endpoint, newOptions);
          const responseDataRetry = await responseRetry.json();

          if (responseDataRetry.code === "PGRST301" && responseDataRetry.message === "JWT expired") {
            window.alert('Login expired, please log out and log in again.');
          }
          return { data: responseDataRetry, error: null };
        } else {
          console.log('Token refresh failed');
          window.alert('Login expired, please sign in again.');
          return { data: null, error: 'Token refresh failed' };
        }
      }

      console.log('patchPgData: ', responseData);
      return { data: responseData, error: null };

    } catch (error) {
      console.error('patchPgData error: ', error);
      return { data: null, error: error.message };
    }
  } else {
    console.error('patchPgData Error: Horizontal filtering required. Endpoint:', endpoint);
    return { data: null, error: 'Invalid endpoint or missing horizontal filter' };
  }
}


// REST remove = CRUD Delete; TODO: remove window.alert and return a meaningful error
// postgrest supports an extension pg_safeupdate that will prevent accidental full-table deletes
// https://postgrest.org/en/stable/integrations/pg-safeupdate.html#block-full-table-operations
// unfortunately, AWS doesn't support that extension
// https://docs.aws.amazon.com/AmazonRDS/latest/PostgreSQLReleaseNotes/postgresql-extensions.html
// It could maybe be added as pg_tle, a, AWS Trusted Language Extensions
// https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/Appendix.PostgreSQL.CommonDBATasks.Extensions.html
// But I'm not going to worry about that right now, and instead add a parameter LIMIT that defaults to 1
// https://postgrest.org/en/stable/references/api/tables_views.html#limited-update-delete
// TODO: add pg_safeupdate ^^
// "To delete rows in a table, use the DELETE verb plus Horizontal Filtering. "
//https://postgrest.org/en/stable/references/api/tables_views.html#delete
export async function deletePgData(endpoint = "/error", data = {}, storedSession, url = rdsUri) {
  let Token = "";
  console.log('storedSession: ', storedSession);
  console.log('typeof storedSession: ', typeof storedSession);

  if (storedSession && typeof storedSession === "object" && storedSession?.data?.session) {
    Token = storedSession.data.session.access_token;
  } else if (typeof storedSession === "string") {
    Token = storedSession;
  } else {
    Token = "";
  }

  console.log("Token after type check: ", Token);

  const options = {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': "application/json",
      'Prefer': 'return=representation'
    },
    credentials: 'include',
    redirect: "follow",
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify(data),
  };

  if (Token) {
    options.headers['Authorization'] = `Bearer ${Token}`;
  }

  try {
    console.log(url + endpoint, options);
    const response = await fetch(url + endpoint, options);
    const responseData = await response.json();

    if (responseData.code === "PGRST301" && responseData.message === "JWT expired") {
      const success = await graceful_token_refresh(storedSession);
      if (success) {
        let newSession = { ...storedSession };
        newSession.data.session.access_token = success;
        storedSession.login(newSession);

        options.headers['Authorization'] = `Bearer ${success}`;
        const responseRetry = await fetch(url + endpoint, options);
        const responseDataRetry = await responseRetry.json();
        return { data: responseDataRetry, error: null };
      } else {
        window.alert('Login expired, please sign in again.');
      }
    }

    console.log('deletePgData: ', responseData);
    return { data: responseData, error: null };
  } catch (error) {
    console.error('deletePgData error: ', error);
    return { data: null, error: error.message };
  }
}




// signup is an http POST against a /rpc/signup endpoint, referencing a basic_auth.unverified_users table that is not publicly exposed
export async function signupWithPg(data = {}, url = rdsUri, endpoint = signupEndpoint) {
  //console.log('inside signupWithPg', JSON.stringify(data)) 
  const options = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': "application/json",
      'Prefer': 'return=representation',
    },
    // TODO: include credentials, and set a cookie with password to autofill link for auth?
    redirect: "follow", // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  }
  try {
    console.log(url + endpoint, options)


    const response = await fetch(url + endpoint, options);

    if (!response.ok) {
      //throw new Error('Network response was not ok');
      const responseData = await response.json();
      console.log('signupWithPg: ', responseData);
      return { data: null, error: responseData };
    }

    const responseData = await response.json();

    console.log('signupWithPg: ', responseData);

    return { data: responseData, error: null };
  } catch (error) {
    console.error('signupWithPg error: ', error);
    throw error;
  }
}



// register is an http POST against a /rpc/verify endpoint, referencing a basic_auth.unverified_users table that is not publicly exposed
// ToDo: we now want this to set a new password, other than the default
export async function registerWithPg(data = {}, url = rdsUri, endpoint = "/rpc/verify") {
  //console.log('inside registerWithPg', JSON.stringify(data)) 
  const options = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': "application/json",
      'Prefer': 'return=representation',
    },
    // TODO: include credentials, and set a cookie with password to autofill link for auth?
    redirect: "follow", // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  }
  try {
    console.log(url + endpoint, options)


    const response = await fetch(url + endpoint, options);

    if (!response.ok) {
      //throw new Error('Network response was not ok');
      const responseData = await response.json();
      console.log('registerWithPg: ', responseData);
      return { data: null, error: responseData };
    }

    const responseData = await response.json();

    console.log('registerWithPg: ', responseData);
    // {token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiY…DY3fQ.EteQmP2wvVnM1RCG6z1Ioe5iLacT9LLez0k5X5GVe74'}token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYXV0aGVudGljYXRlZF91c2VyIiwiZW1haWwiOiJkb3VnbGFzLmUubWNraW5sZXlAZ21haWwuY29tIiwiZXhwIjoxNzAxNzQyMDY3fQ.EteQmP2wvVnM1RCG6z1Ioe5iLacT9LLez0k5X5GVe74"[[Prototype]]: Object}

    return { data: responseData, error: null };
  } catch (error) {
    console.error('registerWithPg error: ', error);
    throw error;
  }
}


// login is an http POST against a /rpc/login endpoint, referencing a basic_auth.users table that is not publicly exposed
export async function loginWithPg(data = {}, url = rdsUri, endpoint = "/rpc/login") {
  //console.log('inside loginWithPg', JSON.stringify(data)) 
  const options = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': "application/json",
      'Prefer': 'return=representation',
    },
    credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
    redirect: "follow", // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  }
  try {
    console.log(url + endpoint, options)


    const response = await fetch(url + endpoint, options);

    if (!response.ok) {
      //throw new Error('Network response was not ok');
      const responseData = await response.json();
      console.log('loginWithPg: ', responseData);
      return { data: null, error: responseData };
    }

    const responseData = await response.json();

    console.log('loginWithPg: ', responseData);

    return { data: responseData, error: null };
  } catch (error) {
    console.error('loginWithPg error: ', error);
    throw error;
  }
}

// TODO: add a /rpc/logout endpoint that removes the refresh-token cookie

// https://stackoverflow.com/questions/5285940/correct-way-to-delete-cookies-server-side

/**
 * Sending the same cookie value with ; expires appended will not destroy the cookie.

Invalidate the cookie by setting an empty value and include an expires field as well:

Set-Cookie: token=deleted; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT
Note that you cannot force all browsers to delete a cookie. The client can configure the browser in such a way that the cookie persists, even if it's expired. Setting the value as described above would solve this problem.
 */
export async function logoutWithPg(url = rdsUri, endpoint = "/rpc/logout") {
  const options = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': "application/json",
      'Prefer': 'return=representation',
    },
    credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
    redirect: "follow", // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin'
  }
  try {
    console.log(url + endpoint, options)
    const response = await fetch(url + endpoint, options);
    if (!response.ok) {
      //throw new Error('Network response was not ok');
      console.log('logoutWithPg: ', response);
      return { data: null, error: response };
    }
    console.log('logoutWithPg: ', response);
    return { data: response, error: null };
  } catch (error) {
    console.error('logoutWithPg error: ', error);
    throw error;
  }
}

/**
 * execute rpc/update_user_password
 */
// const { data, error } = await changePasswordWithPg( // basic_auth.update_user_password input_email TEXT, input_pass TEXT, input_code TEXT
//           {
//             input_code: formDataEscape.code,
//             input_email: formDataEscape.email,
//             input_pass: formDataEscape.password
//           })
export async function changePasswordWithPg(data = {}, url = rdsUri, endpoint = "/rpc/update_user_password") {
  const options = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      'Content-Type': "application/json",
      'Prefer': 'return=representation',
    },
    credentials: 'include', // need credentials included also when we intend to write a cookie https://stackoverflow.com/questions/73730172/response-header-set-cookie-doesnt-store-cookies-in-browser  https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
    redirect: "follow", // manual, *follow, error
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify(data)
  }
  try {
    console.log(url + endpoint, options)
    const response = await fetch(url + endpoint, options);
    if (!response.ok) {
      //throw new Error('Network response was not ok');
      console.log('changePasswordWithPg: ', response);
      return { data: null, error: response };
    }
    console.log('changePasswordWithPg: ', response);
    return { data: response, error: null };
  } catch (error) {
    console.error('changePasswordWithPg error: ', error);
    throw error;
  }
}
