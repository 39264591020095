
//https://hackernoon.com/understanding-session-management-using-react-router-v6

import { useActionData, useNavigation, useOutletContext } from 'react-router';
import { useNavigate, Form } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../styles/LoginSignUpStyles/login.css';
import { Grid, Header, Button, Segment } from 'semantic-ui-react';
import { CurrencyLira } from '@mui/icons-material';
import { getPgData, loginWithPg } from '../data/rdsClient';

export const LoginView = () => {
  // Introduce State for Error Handling
  const [errorMessage, setErrorMessage] = useState(null);
  const handleError = (message) => setErrorMessage(message);
  const resetError = () => setErrorMessage(null);

  // State for Success Handling
  const [successMessage, setSuccessMessage] = useState(null);
  const handleSuccess = (message) => setSuccessMessage(message);
  const resetSuccess = () => setSuccessMessage(null);

  const navigation = useNavigation();
  const navigate = useNavigate();
  const session = useOutletContext(); // Access SessionProvider context
  const isLoading = navigation.state === 'loading' || navigation.state === 'submitting';

  const data = useActionData();

  useEffect(() => {
    console.log("LoginView useEffect");

    if (data === null || data === undefined) {
      // Handle the case when data is null or undefined (initial page load)
      return;
    }

    if (data && !data.error) {
      console.log('data=', data);
      if ('session' in data) {
        // Valid session found, login and store session data
        session.login({ data }); // Pass the full session data object

        // Redirect the user after successful login
        navigate('/');
      } else if ('code' in data) {
        // Handle invalid login credentials
        handleError(data.message);
        navigate('/login');
      }
    } else if (data && data.error) {
      handleError(`Error: ${data.error}`);
      navigate('/');
    }
  }, [data, navigate, session]); // Ensure useEffect only re-runs when data, navigate, or session changes

  return (
    <div style={{ height: '100vh', backgroundColor: '#0f0f0f', color: 'white' }}>
      <h1 id="login-header">Single Pay News</h1>
      <h3 id="login-subheader">Login</h3>
      <div className='form-container'>
        <Form action='/login' method='post'>
          <label className='login-label'>
            Email:
            <input
              disabled={isLoading}
              type="text"
              name="Email"
              placeholder='Email'
              className='login-input'
            />
          </label>
          <label className='login-label'>
            Password:
            <input
              disabled={isLoading}
              type="password"
              name="password"
              placeholder='password'
              className='login-input'
            />
          </label>
          <button disabled={isLoading} id='login-button'>
            {isLoading ? 'loading...' : 'Login'}
          </button>
        </Form>
        <h3 id="forgot-password-link"><a href='/forgot-password'>Forgot Password?</a></h3>
        {data && data.error && <div>{data.error}</div>}
        {/* Displaying Error Messages */}
        {errorMessage && (
          <div className="error-message">
            <p>{errorMessage}</p>
            <button onClick={resetError}>Close</button>
          </div>
        )}
        {/* Displaying Success Messages */}
        {successMessage && (
          <div className="success-message">
            <p>{successMessage}</p>
            <button onClick={resetSuccess}>Close</button>
          </div>
        )}
      </div>
    </div>
  );
};

// Action function for handling login form submission
export async function action({ request }) {
  console.log('action called');
  const formData = await request.formData();
  const formDataEscape = {
    Email: formData.get('Email'),
    password: formData.get('password'),
  };

  try {
    const { data: loginData, error: loginError } = await loginWithPg({
      input_email: formDataEscape.Email,
      pass: formDataEscape.password,
    });

    if (loginError) {
      console.log('bad login credentials', loginError);
      return loginError;
    }

    console.log('loginWithPg: ', loginData);

    const { data: userPublicData, error: userPublicError } = await getPgData(
      `/userpublic?id=eq.${loginData[0].id}`
    );
    if (userPublicError) {
      console.error('Error:', userPublicError);
      return { error: userPublicError.message };
    }

    const { data: userPrivateData, error: userPrivateError } = await getPgData(
      `/userprivate?id=eq.${loginData[0].id}`,
      {},
      loginData[0].token.token
    );
    if (userPrivateError) {
      console.error('Error:', userPrivateError);
      return { error: userPrivateError.message };
    }

    const ret = {
      user: {
        email_confirmed_at: "todo",
        id: loginData[0].id,
        username: userPublicData[0].name,
      },
      session: {
        access_token: loginData[0].token.token,
        token_type: "bearer",
        expires_in: 3600,
        refresh_token: "todo",
        userPublic: userPublicData[0],
        userPrivate: userPrivateData[0],
      },
    };

    console.log('ret: ', ret);
    return ret;
  } catch (error) {
    if (error.message === 'Invalid credentials') {
      return { error: 'Invalid credentials.' };
    }
    throw error;
  }
}
